class nav {

  constructor() {
    $(window).resize(() => {
      this.handleResize();
    });

    $(document).ready(() => {
      /*
       * nav toggle to show sidebar menu.
       */
      this.handleResize();

      var $menu_toggle = $('#menu-toggle'); // menu mobile icon 
      var $wrapper = $('#wrapper');
      var $location = $('.navbar-header .location')
      var $logo = $('.navbar-brand .logo');
      var $footerLink = $('.footer-links a');

      $footerLink.on('click', function(e) {
        if(typeof window.tapGlobalFooter === 'function') {
          window.tapGlobalFooter($(this));
        }
      })

      $logo.on('click', function(){
        if(typeof window.tapGlobalNav === 'function') {
          window.tapGlobalNav('logo');
        }
      })

      // show/hide side bar
      $menu_toggle.on('click', () => {
        // e.preventDefault();

        // add 'padding-left' to '.wrapper'
        $wrapper.toggleClass('toggled');

        // add animation to hamburger menu '#menu-toggle'
        $menu_toggle.toggleClass('active');

        if ($wrapper.hasClass('toggled')) {
          this.showSidebar();
        } else {
          this.hideSidebar();
        }
      });


      // hide side bar    
      $('#page-content-wrapper').on('click', (e) => {
        if (window.matchMedia('(min-width: 992)').matches) {
          return;
        }

        // if sidebar is active
        // onclick '#page-content-wrapper' will hide it

        if ($wrapper.hasClass('toggled')) {
          // add 'padding-left' to '.wrapper'
          $wrapper.removeClass('toggled');

          // add animation to hamburger menu '#menu-toggle'
          $menu_toggle.removeClass('active');

          // add animation to location '#menu-toggle'
          $location.removeClass('active');

          this.hideSidebar();
        }
      });
      // nav toggle to show sidebar menu END



      /*
       * Nav Active class
       * Match URL path and add ".active"
       * to 'nav li' 
       */

      $(function() {
        var activePage = window.location.pathname;

        $('.navigation li a').each(function() {

          var linkPage = $(this).attr('data-destination');
          if (activePage == linkPage) {
            // remove '.active' from all 'nav li'
            $('.navigation li')
              .removeClass('active')
              // add ".active" to all matching 'nav li'
              .find(`[data-destination="${linkPage}"]`)
              .attr('aria-current','page')
              .closest('li')
              .addClass('active');
          } else if (activePage == '/payment-estimator'){
            console.log('link name',$('.navigation li').find('[data-destination="/special-offers"]'.attr('href')))
            // add ".active" to /special-offers if page is /payment-estimator 'nav li'
            $('.navigation li')
              .find('[data-destination="/special-offers"]')
              .attr('aria-current','page')
              .closest('li')
              .addClass('active');
          }
        });

      });
      // Nav Active class END

      // fix issue of nav-bar flashing on page load
      $('#sidebar-wrapper').show();

      $('.navbar-collapse').addClass('collapse');

      $('#skipnavlink').click(function(e) {
        console.log('skipping')
        e.preventDefault();
        $('#first-content').attr('tabindex', '-1').focus();
      });

    }); //document ready
  }

  focusableQuery = 'a,button,iframe,object,embed,[contenteditable],input,select,textarea';

  disableElm($elm) {
    $elm.attr('aria-hidden', 'true');
    $elm.attr('disabled', 'true');
    $elm.attr('tabindex', '-1');
  }

  enableElm($elm) {
    $elm.removeAttr('aria-hidden');
    $elm.removeAttr('disabled');
    $elm.removeAttr('tabindex');
  }


  handleResize() {
    const $menu_toggle = $('#menu-toggle'); // menu mobile icon 
    const $navbar = $('#navbar');
    const $wrapper = $('#wrapper');
    const isMobile = $menu_toggle.is(':visible');

    if (isMobile) {
      this.enableElm($menu_toggle);

      // ,.search-box
      $navbar.find(this.focusableQuery).each((i, elm) => {
        this.disableElm($(elm));
      });

      if ($wrapper.hasClass('toggled')) {
        this.showSidebar();
      } else {
        this.hideSidebar();
      }
    } else {
      $wrapper.removeClass('toggled');
      $menu_toggle.removeClass('active');

      $navbar.find(this.focusableQuery).each((i, elm) => {
        this.enableElm($(elm));
      });
      this.disableElm($menu_toggle);
      this.hideSidebar();
    }
  }

  hideSidebar() {
    $('#sidebar-wrapper')
      .find(this.focusableQuery).each((i, elm) => {
        this.disableElm($(elm));
      });
  }

  showSidebar() {
    $('#sidebar-wrapper').find(this.focusableQuery).each((i, elm) => {
      this.enableElm($(elm));
    });
  }
}